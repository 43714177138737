import type { CombinedSlicesState } from './interfaces';

import create from 'zustand'
import { devtools, persist } from "zustand/middleware";
import { createStatusGroupsSlice } from './statusGroupSlice';
import { createDatasetsSlice } from './datasetSlice';
import { createLoginOptionSlice } from './loginOptionSlice';

// *NOTES: this store here now can be seen from redux devtools and also persist into LocalStorage with the name 'bound-storage'
export const useBoundStore = create<CombinedSlicesState>()(
  devtools(
    persist(
      (...a) => ({
        ...createStatusGroupsSlice(...a),
        ...createDatasetsSlice(...a),
        ...createLoginOptionSlice(...a)
      }),
      {
        name: "bound-storage",
        // getStorage: () => localStorage // change if you want to change the default storage, default as localStorage
      }
    ),
  )
)