interface CsvFileInterface {
  name: string
  contents: string
}

export class CsvFile implements CsvFileInterface {
  name: string
  contents: string

  constructor(name: string, contents: string) {
    this.name = name
    this.contents = contents
  }
}

class CsvFileRenderer {
  files: CsvFile[] = []

  constructor(csvFiles: CsvFile[]) {
    this.files = csvFiles
  }

  render() {
    const helper = `

def lw_write_to_file(filename, contents):
    text_file = open(filename, "w")
    text_file.write(contents)
    text_file.close()

`

    return (
      helper +
      this.files
        .map((a) => {
          return `
lw_write_to_file("${a.name}", """${a.contents}""")
`
        })
        .join('\n')
    )
  }
}

export default CsvFileRenderer
