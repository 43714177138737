import dayjs from '../../utils/dayjs'

import { del, get, post, put } from '.'
import { Group } from './groups'
import { Dayjs } from 'dayjs'

export enum Role {
  Student = 'User',
  Admin = 'Admin',
}

export interface User {
  id: string
  name: string
  role: Role
  study_groups: Group[]
  discord_id: string
  avatarUrl: string
  message?: string
}
export interface UserInGroup {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  role: Role;
  linkedAccounts: string;
  avatarUrl: string;
  discord_id: string;
  token_expires_in: number;
  stripe_id: null;
  pm_type: null;
  pm_last_four: null;
  trial_ends_at: null;
  most_recent_activity: null;
  current_week: number;
  quest_order: number;
  task_order: number;
  pivot: Pivot;
}

export interface IUserPurchaseProgram {
  program_id: number;
  title: string;
  purchase_date: string;
  start_date: string;
  program_start_time: ProgramStartTime[];
  user_voucher_id: number;
}

export interface ProgramStartTime {
  id: number;
  program_id: number;
  start_time: string;
  end_time: string;
}


export interface Pivot {
  study_group_id: number;
  user_id: number;
}

export interface ReviewPointEvent {
  id: string
  type: 'leader' | 'follower' | 'none'
  description: string
  point: number
  timestamp: Dayjs
  created_at: Dayjs
}

export function getMe(): Promise<User> {
  return get('/api/me')
}

export function getUser(id: string | number): Promise<User> {
  return get('/api/users/' + id)
}

export function getUsers(): Promise<User[]> {
  return get('/api/users/')
}

export function getMyPurchasedProgram(url: string): Promise<IUserPurchaseProgram[]> {
  return get(url)
}

export function createUser(newUser: { name: string; discord_id: string }) {
  return post<User>('/api/users', newUser)
}

export function updateUser(
  userId,
  newUser: { name: string; discord_id: string; role: string }
) {
  return put<User>(userId && '/api/users/' + userId, newUser)
}

export function deleteUser(userId) {
  return del<User>(userId && '/api/users/' + userId, {})
}

export function joinProgram(user: {
  userId: string
  programId: string
  contactDiscordChannelId: string
}) {
  return post(`/api/users/${user.userId}/status`, user)
}

export interface StudyGroupStatus {
  id: number
  name: string
  discord_server_id: string
  discord_peer_review_voice_category_id: string
  is_free_trial: 0 | 1
  program_id: number
  start_date: string
  created_at: string
  updated_at: string
  lastWeekCompletionDate: string
  program: StudyGroupStatusProgram
  hasCompletedProgram: boolean
}

export interface StudyGroupStatusProgram {
  progress: number
  id: number
  name: string
  initialReviewPoint: number
  weeks: StudyGroupStatusWeek[]
}

export interface StudyGroupStatusWeek {
  progress: number
  days_left: number
  is_available: boolean
  study_group_week_id: number
  start_date: string
  end_date: string
  order: number
  week_id: number
  completion_date: string
  id: number
  name: string
  program_id: number
  week_image_url: string
  quests: StudyGroupStatusQuest[]
}

export interface StudyGroupStatusQuest {
  id: number
  name: string
  week_id: number
  order: number
  tasks: StudyGroupStatusTask[]
}

export interface StudyGroupStatusTask {
  id: number
  name: string
  quest_id: number
  order: number
  is_available: number
  is_correct: number
  solution: string
  taskable_type: string
}

export interface UserStatus {
  id: number
  name: string
  is_free_trial: 0 | 1
  program_name: string
  program_id: number
  program_image_url: string
  latest_end_date: string
  days_left: number
  has_passed: boolean
  week_count: number
  current_week: number
  week_count_completed: number
  progress: number
  weeks: StatusWeek[]
}

export interface StatusWeek {
  study_group_week_id: number
  study_group_id: number
  start_date: string
  end_date: string
  order: number
  week_id: number
  week_name: string
}

export interface UserProgress {
  user_id: number
  task_id: number
  avatarUrl: string
  name: string
  created_at: string
}

export interface AllUserProgress {
  task_progress: TaskProgress
  on_week_finish: OnWeekFinishProgress
  on_program_finish: User[]
}
export interface TaskProgress {
  [key: number]: UserProgress
}
export interface OnWeekFinishProgress {
  [key: number]: UserProgress
}

function transformDayjs(events: ReviewPointEvent[]): ReviewPointEvent[] {
  const mappedEvents = events.map((a) => {
    a.timestamp = a.created_at

    const testType = parseInt(a.type)
    a.type = testType === 1 ? 'none' : testType === 2 ? 'leader' : 'follower'

    a.timestamp = dayjs(a.timestamp)

    return a
  })

  return mappedEvents
}

export function listUserStatus(userId: string) {
  return async (): Promise<UserStatus[]> => {
    return await get<UserStatus[]>(`/api/users/${userId}/status`)
  }
}

export async function listAllUsersProgress($studyGroupId) {
  const progress = await get<AllUserProgress>(
    '/api/study-group-week/' + $studyGroupId
  )
  return progress
}

export async function listMyStudyGroupStatus($studyGroupId) {
  const progress = await get<StudyGroupStatus>(
    '/api/users/me/status/study-group/' + $studyGroupId
  )
  return progress
}

export async function listMyReviewPoints($programId) {
  const progress = await get<ReviewPointEvent[]>(
    '/api/users/me/status/review-points/' + $programId
  )
  return transformDayjs(progress)
}

export async function listMyStatus() {
  return await get<UserStatus[]>('/api/users/me/status')
}

export function addReviewPoint(
  userId: string,
  programId: string,
  event: {
    type: number
    description: string
    point: number
  }
) {
  return post(
    `/api/review-points/?user_id=${userId}&program_id=${programId}`,
    event
  )
}
