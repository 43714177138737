import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import * as utc from 'dayjs/plugin/utc'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as timezone from 'dayjs/plugin/timezone'
import * as localizedFormat from 'dayjs/plugin/localizedFormat'

export const JP_TIMEZONE = 'Asia/Tokyo'

dayjs.extend(customParseFormat.default)
dayjs.extend(utc.default)
dayjs.extend(timezone.default)
dayjs.extend(localizedFormat.default)
dayjs.locale('ja')

export default dayjs
