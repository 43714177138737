import type { AppProps } from 'next/app'
import Head from 'next/head'
import './_app.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useKonami } from 'react-konami-code'
import { useEffect, useState } from 'react'
import cookies from '../utils/cookies'
import { useRouter } from 'next/router'
import helpers from '../utils/helpers'

const BuildTimestamp = () => {
  const [show, setShow] = useState(true)
  return (
    <>
      {process.env.NEXT_PUBLIC_TIMESTAMP !== '' && show && (
        <div
          onClick={() => setShow(false)}
          className="fixed bottom-0 right-0 p-1 bg-pink-500 text-sm text-white rounded-lg font-bold"
        >
          Most Recent Front End Build: {process.env.NEXT_PUBLIC_TIMESTAMP}
        </div>
      )}
    </>
  )
}

export default function app({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [apiToken, setApiToken] = useState(cookies.getCookie('api-token'))
  const [tokenVisible, setTokenVisible] = useState(false)
  const toggleTokenVisible = () => setTokenVisible(!tokenVisible)

  useEffect(() => {
    const handleRouteChange = (url) => {
      helpers.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    if (typeof window != 'undefined') {
      router.push(window.location.pathname)
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <Head>
        <title>TomoCode</title>
      </Head>
      {useKonami(() => {
        setApiToken(cookies.getCookie('api-token'))
        toggleTokenVisible()
      })}
      <div className={tokenVisible ? 'debug-grid' : ''}>
        <Component {...pageProps} />
      </div>
      <ToastContainer />
      {tokenVisible && (
        <>
          <BuildTimestamp />
          <textarea
            className=" fixed bottom-0 w-full z-50 font-mono bg-pink-500 text-white text-sm left-0"
            style={{ maxWidth: '50vw' }}
            onChange={() => {
              toggleTokenVisible()
            }}
            value={apiToken}
          />
        </>
      )}
    </div>
  )
}
