import cookies from '../../utils/cookies'

function getActualUrl(url: string) {
  return `${process.env.NEXT_PUBLIC_API_URL}${url}`
}

const defaultOptions: RequestInit = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
}

export function fetchOption(options: RequestInit, isFormData = false) {
  const authOptions: RequestInit = {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${cookies.getCookie('api-token')}`,
      ...(!isFormData && { 'Content-Type': 'application/json' }),
    },
  }

  return {
    ...(cookies.getCookie('api-token') === '' ? defaultOptions : authOptions),
    ...options,
  }
}

async function request<T>(
  url: string,
  options: RequestInit,
  isFormData = false
): Promise<T> {
  const res = await fetch(getActualUrl(url), fetchOption(options, isFormData))
  const data = await res.json()
  // if (res.status === 401) document.location = '/'
  if (!res.ok) throw new Error(data.message)

  return data as T
}

export async function get<T>(url: string): Promise<T> {
  return request(url, { method: 'GET' })
}

export async function post<T>(
  url: string,
  body: Record<string, any> | FormData,
  isFormData = false
): Promise<T> {
  const payload = body instanceof FormData ? body : JSON.stringify(body)

  return request(
    url,
    {
      method: 'POST',
      credentials: 'include',
      body: payload,
    },
    isFormData
  )
}

export async function put<T>(url: string, body: any): Promise<T> {
  return request(url, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(body),
  })
}

export async function patch<T>(url: string, body: any): Promise<T> {
  return request(url, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(body),
  })
}

export async function del<T>(url: string, body: any): Promise<T> {
  return request(url, {
    method: 'DELETE',
    credentials: 'include',
    body: JSON.stringify(body),
  })
}

// server-sent-event
export async function sse(url: string, options: RequestInit) {
  const res = await fetch(getActualUrl(url), fetchOption(options))
  if (!res.body) throw new Error('result body is empty')
  return res.body.pipeThrough(new TextDecoderStream()).getReader()
}
