import type { StateCreator } from "zustand"
import type { CombinedSlicesState, LoginOptionState} from "./interfaces"

const DEFAULT_STATE = ""

const LOGIN_OPTIONS = {
  ADD_TO_FREE_TRIAL: "add_to_free_trial"
} as const

export const createLoginOptionSlice: StateCreator<
  CombinedSlicesState,
  [
    ["zustand/devtools", never],
    ["zustand/persist", unknown]
  ],
  [],
  LoginOptionState
> = (set, _get) => ({
  loginOption: DEFAULT_STATE,
  saveLoginOption(loginOption) {
    if (loginOption === LOGIN_OPTIONS.ADD_TO_FREE_TRIAL) {
      set({ loginOption: loginOption })
    }
  },
  resetLoginOption() {
    set({loginOption: ''})
  }
})