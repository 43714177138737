async function deleteAllCookies() {
  return new Promise<void>((resolve) => {
    const dummyCookies: string[] = []
    for (let i = 0; i < 9001; i++) {
      document.cookie = `cookie_num_${i}=${i}`
      dummyCookies.push(`cookie_num_${i}`)
    }

    setTimeout(() => {
      for (let i = 0; i < dummyCookies.length; i++) {
        const name = dummyCookies[i]

        const blankCookie =
          name +
          '=;domain=' +
          window.location.hostname +
          ';path=' +
          '/' +
          ';expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        document.cookie = blankCookie
      }
      resolve()
    }, 0)
  })
}

function getCookie(cname: string) {
  if (typeof document !== 'undefined') {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
  }
  return ''
}

function setCookie(cname, cvalue) {
  const d = new Date()
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export default {
  setCookie,
  getCookie,
  deleteAllCookies,
}
