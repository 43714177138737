import type { CombinedSlicesState, StatusGroupsState } from "./interfaces";
import type { StateCreator } from "zustand"
import { listMyStudyGroupStatus } from "app/api/users";

const DEFAULT_STATE = {}

export const createStatusGroupsSlice: StateCreator<
  CombinedSlicesState,
  [
    ["zustand/devtools", never],
    ["zustand/persist", unknown]
  ],
  [],
  StatusGroupsState
> = (set, get) => ({
  statusGroups: DEFAULT_STATE,
  resetGroupStatus() {
    set(() => ({statusGroups: DEFAULT_STATE}))
  },
  async saveGroupStatus(studyGroupId, force = false) {
    const currentStore = get()
    if (!studyGroupId) { return }

    if(!currentStore.statusGroups[`${studyGroupId}`] || force) {
      const newData = await listMyStudyGroupStatus(studyGroupId)

      set((
        // { statusGroups }
      ) => ({
        statusGroups: {
          [`${studyGroupId}`]: newData
        }
      }))
    } else if (currentStore.statusGroups[`${studyGroupId}`]) {
      console.debug("this study group have been add")
    }
  },
})
