import { toast } from 'react-toastify'
import cookies from './cookies'
import { useRouter } from 'next/router'
import { useBoundStore } from 'stores/boundStore'

type IClass = {
  [key: string]: boolean
}

function isIClass(val: any) {
  if (typeof val !== 'object') {
    return false
  }
  if (Object.keys(val).length !== 1) {
    return false
  }
  return true
}

export function classNames(...classes: any) {
  return classes
    .map((val: any) => {
      if (typeof val === 'string') {
        return val
      }

      if (isIClass(val)) {
        const classVal: IClass = val
        const [key] = Object.keys(classVal)
        if (classVal[key]) {
          return key
        }
        return ''
      }
      return ''
    })
    .join(' ')
}

export function arrayMove(arr: any[], old_index: number, new_index: number) {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr // for testing purposes
}

export const camelCaseConvert = (source: any): any => {
  if (typeof source !== 'object') return source

  if (typeof source === 'undefined' || source === null) return source

  if (Array.isArray(source)) {
    return source.map(camelCaseConvert)
  }

  const keys = Object.keys(source).map((key) => ({
    original: key,
    changed: key
      .split('_')
      .map((k, i) => (i == 0 ? k : k.charAt(0).toUpperCase() + k.slice(1)))
      .join(''),
  }))

  const converted: any = {}

  keys.forEach(({ original, changed }) => {
    converted[changed] = camelCaseConvert(source[original])
  })

  return converted
}

export const underscoreConvert = (source: any): any => {
  if (typeof source !== 'object') return source

  if (typeof source === 'undefined' || source === null) return source

  if (Array.isArray(source)) {
    return source.map(underscoreConvert)
  }

  const keys = Object.keys(source).map((key) => ({
    original: key,
    changed: key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
  }))

  const converted: any = {}

  keys.forEach(({ original, changed }) => {
    converted[changed] = underscoreConvert(source[original])
  })

  return converted
}

export const toastSuccess = (msg: string) =>
  toast.success(msg, {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      background: '#77db89',
    },
    theme: 'colored',
  })

export const toastError = (msg: string) =>
  toast.error(msg, {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      background: '#e77975',
    },
    theme: 'colored',
  })

export const makeDateString = (d: Date) =>
  `${d.getFullYear()}` +
  '-' +
  `${d.getMonth() + 1}`.padStart(2, '0') +
  '-' +
  `${d.getDate()}`.padStart(2, '0')

export const pageview = (url) => {
  if (process.env.NODE_ENV !== 'development') {
    window.gtag('config', `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`, {
      page_path: url,
    })
  }
}

// log specific events happening.
export const event = ({ action, params }) => {
  if (process.env.NODE_ENV !== 'development') {
    window.gtag('event', action, params)
  }
}

const jaHumanReadableToHour = (datetime: string) => {
  const dt = datetime.includes(' ')
    ? new Date(datetime.split(' ').join('T').concat('Z'))
    : new Date(datetime)

  // Cause we are generate UTC date so to get the right time, we need to get the UTC one
  const [year, month, day, hour, _] = [
    dt.getUTCFullYear(),
    dt.getUTCMonth() + 1,
    dt.getUTCDate(),
    dt.getUTCHours(),
    dt.getUTCMinutes(),
  ].map((d) => `${d}`)

  return `${year}年${month}月${day}日${hour}時`
}

const jaHumanReadable = (datetime: string) => {
  const dt = datetime.includes(' ')
    ? new Date(datetime.split(' ').join('T').concat('Z'))
    : new Date(datetime)

  // Cause we are generate UTC date so to get the right time, we need to get the UTC one
  const [year, month, day, hour, minute] = [
    dt.getUTCFullYear(),
    dt.getUTCMonth() + 1,
    dt.getUTCDate(),
    dt.getUTCHours(),
    dt.getUTCMinutes(),
  ].map((d) => `${d}`)
  return `${year}年${month}月${day}日 ${`${hour}`.padStart(
    2,
    '0'
  )}:${`${minute}`.padStart(2, '0')}`
}

const myUserId = () => {
  return parseInt(cookies.getCookie('userId'))
}

export const setPrevButton = (statusGroupID: string) => {
  const router = useRouter()
  const { id } = router.query
  const studyGroupsStatus = useBoundStore((state) => state.statusGroups)
  const studyGroupStatus = studyGroupsStatus[statusGroupID]

  const allWeeks = studyGroupStatus?.program.weeks

  let taskPre: any
  let questIndex: number
  let taskIndex: number
  let weekIndex: number
  let prevUrl: string

  for (const i in allWeeks) {
    const allQuests = studyGroupStatus?.program.weeks[i].quests
    if (allWeeks[i].quests === allQuests) {
      for (const j in allQuests) {
        const allTask = studyGroupStatus?.program.weeks[i].quests[j].tasks
        if (allQuests[j].tasks === allTask) {
          for (const k in allTask) {
            if (allTask[k].id === Number(id)) {
              taskIndex = Number(k) - 1
              if (taskIndex >= 0) {
                taskPre = allTask[taskIndex]
                prevUrl = `/study-group/${statusGroupID}/quests/${taskPre?.id}`
                return prevUrl
              } else {
                questIndex = Number(j) - 1
                if (questIndex >= 0) {
                  taskPre = allQuests[questIndex].tasks.slice(-1).pop()
                  prevUrl = `/study-group/${statusGroupID}/quests/${taskPre?.id}`
                  return prevUrl
                } else {
                  weekIndex = Number(i) - 1
                  if (weekIndex >= 0) {
                    taskPre = allWeeks[weekIndex].quests
                      .slice(-1)
                      .pop()
                      ?.tasks.slice(-1)
                      .pop()
                    prevUrl = `/study-group/${statusGroupID}/quests/${taskPre?.id}`
                    return prevUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

export const setNextButton = (statusGroupID: string) => {
  const router = useRouter()
  const { id } = router.query
  const studyGroupsStatus = useBoundStore((state) => state.statusGroups)
  const studyGroupStatus = studyGroupsStatus[statusGroupID]

  const allWeeks = studyGroupStatus?.program.weeks

  let taskNext: any
  let questIndex: number
  let taskIndex: number
  let weekIndex: number
  let nextUrl: string

  for (const i in allWeeks) {
    const allQuests = studyGroupStatus?.program.weeks[i].quests
    if (allWeeks[i].quests === allQuests) {
      for (const j in allQuests) {
        const allTask = studyGroupStatus?.program.weeks[i].quests[j].tasks
        if (allQuests[j].tasks === allTask) {
          for (const k in allTask) {
            if (allTask[k].id === Number(id)) {
              taskIndex = Number(k) + 1
              if (taskIndex < allTask.length) {
                taskNext = allTask[taskIndex]
                nextUrl = `/study-group/${statusGroupID}/quests/${taskNext?.id}`
                return nextUrl
              } else {
                questIndex = Number(j) + 1
                if (questIndex < allQuests.length) {
                  taskNext = allQuests[questIndex].tasks[0]
                  nextUrl = `/study-group/${statusGroupID}/quests/${taskNext?.id}`
                  return nextUrl
                } else {
                  weekIndex = Number(i) + 1
                  if (weekIndex < allWeeks.length) {
                    taskNext = allWeeks[weekIndex].quests[0].tasks[0]
                    nextUrl = `/study-group/${statusGroupID}/quests/${taskNext?.id}`
                    return nextUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

function extractTasks(data: any): Array<{
  task_user_id: number
  is_available: boolean
  compositeKey: string
}> {
  const tasks: Array<{
    task_user_id: number
    task_id: number
    is_available: boolean
    compositeKey: string
  }> = []
  for (let i = 0; i < data.program.weeks.length; i++) {
    for (let j = 0; j < data.program.weeks[i].quests.length; j++) {
      for (let k = 0; k < data.program.weeks[i].quests[j].tasks.length; k++) {
        tasks.push({
          task_user_id: data.program.weeks[i].quests[j].tasks[k].task_user_id,
          is_available: data.program.weeks[i].quests[j].tasks[k].is_available,
          task_id: data.program.weeks[i].quests[j].tasks[k].id,
          compositeKey: `${data.program.weeks[i].order
            .toString()
            .padStart(3, '0')}-${data.program.weeks[i].quests[j].order
            .toString()
            .padStart(3, '0')}-${data.program.weeks[i].quests[j].tasks[k].order
            .toString()
            .padStart(3, '0')}`,
        })
      }
    }
  }
  tasks.sort((a, b) => a.compositeKey.localeCompare(b.compositeKey))
  return tasks
}

function isNextAvailable(tasks: any[], task_id: number): boolean {
  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].task_id === task_id && tasks[i].is_available) {
      if (i !== tasks.length - 1 && tasks[i + 1].is_available) {
        return true
      } else {
        return false
      }
    }
  }
  return false
}

function isPreviousAvailable(tasks: any[], task_id: number): boolean {
  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].task_id === task_id) {
      if (i !== 0 && tasks[i - 1].is_available) {
        return true
      } else {
        return false
      }
    }
  }
  return false
}

export function isPreviousTaskAvailable(data: any, task_id: number): boolean {
  if (!data?.program) return false
  const tasks = extractTasks(data)
  if (tasks.length === 0) return false
  return isPreviousAvailable(tasks, task_id)
}

export function isNextTaskAvailable(data: any, task_id: number): boolean {
  if (!data?.program) return false
  const tasks = extractTasks(data)
  if (tasks.length === 0) return false
  return isNextAvailable(tasks, task_id)
}

const helpers = {
  classNames,
  arrayMove,
  camelCaseConvert,
  underscoreConvert,
  pageview,
  event,
  jaHumanReadableToHour,
  jaHumanReadable,
  myUserId,
  isNextTaskAvailable,
  isPreviousTaskAvailable,
}

export default helpers
