import { Dataset, getDatasetDetail, getDatasets } from 'app/api/dataset'
import dayjs from 'dayjs'
import { toastError } from 'utils/helpers'
import { CsvFile } from 'utils/pyodide/csvFileRenderer'
import type { StateCreator } from 'zustand'
import type { CombinedSlicesState, DatasetState } from './interfaces'

const DEFAULT_STATE_DATASETS = [] as Dataset[]
const DEFAULT_STATE_DATASETS_DETAIL = {}

export const createDatasetsSlice: StateCreator<
  CombinedSlicesState,
  [['zustand/devtools', never], ['zustand/persist', unknown]],
  [],
  DatasetState
> = (set, get) => ({
  datasets: DEFAULT_STATE_DATASETS,
  datasetsDetail: DEFAULT_STATE_DATASETS_DETAIL,
  updatedAt: dayjs(),
  async initDatasets() {
    const currentStore = get()
    const diffTimeFromLastUpdated = dayjs().diff(
      currentStore.updatedAt,
      'hours'
    )
    // if (notUpdateDatasets(currentStore.datasets.length, diffTimeFromLastUpdated, force)) return
    if (diffTimeFromLastUpdated > 6) {
      set({ datasetsDetail: DEFAULT_STATE_DATASETS_DETAIL, updatedAt: dayjs() })
    }

    try {
      const listDatasets = await getDatasets()
      if (listDatasets.length === 0) return
      set({ datasets: listDatasets })
    } catch (error) {
      console.log(error)
      toastError(`There is error occur when we retrieve the list of datasets`)
    }
  },
  async hydrateDatasetDetail(datasetDetails, datasetName, content) {
    const datasetsDetailEntries = Object.entries(datasetDetails)
    if (datasetsDetailEntries.length < 3) {
      set((currentState) => ({
        datasetsDetail: {
          ...currentState.datasetsDetail,
          [datasetName]: content,
        },
        updatedAt: dayjs(),
      }))
      return
    }
    set({
      datasetsDetail: {
        ...Object.fromEntries(
          datasetsDetailEntries.filter((_dataset, index) => index !== 0)
        ),
        [datasetName]: content,
      },
      updatedAt: dayjs(),
    })
  },
  async getDatasetDetail(datasetName) {
    const currentStore = get()
    if (currentStore.datasetsDetail[datasetName]) {
      return new CsvFile(datasetName, currentStore.datasetsDetail[datasetName])
    }
    const datasetFindResult = currentStore.datasets.find(
      (dataset) => dataset.filename === datasetName
    )
    if (!datasetFindResult) {
      toastError(
        `We currently can't find the dataset file with the name "${datasetName}" in S3 that need to be used for execution`
      )
      return null
    }
    try {
      const resultArray = await getDatasetDetail(datasetFindResult.id)
      const resultConcat = resultArray.join('')
      currentStore.hydrateDatasetDetail(
        currentStore.datasetsDetail,
        datasetName,
        resultConcat
      )
      return new CsvFile(datasetName, resultConcat)
    } catch (error) {
      console.log(error)
      return null
    }
  },
  resetDatasetSlice() {
    set({
      datasets: DEFAULT_STATE_DATASETS,
      datasetsDetail: DEFAULT_STATE_DATASETS_DETAIL,
      updatedAt: dayjs(),
    })
  },
})

//** Helper functions */
// function notUpdateDatasets(datasetLength: number, diffTimeFromLastUpdated: number, forceToUpdate: boolean) {
//   const baseRule = datasetLength > 0 && diffTimeFromLastUpdated < 6
//   return baseRule || (baseRule && !forceToUpdate)
// }
