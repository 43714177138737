import { del, get, post, put } from ".";

const API_ROUTE = '/api/datasets'

// Generated by https://quicktype.io
export interface Dataset {
  id:         number;
  filename:   string;
  key:        string;
  created_at: string;
  updated_at: string;
}
// Generated by https://quicktype.io
export interface DatasetPutRequest {
  filename: string;
}


export function getDatasets() {
  return get<Dataset[]>(API_ROUTE)
}

export function getDatasetDetail(datasetId: number) {
  return get<string[]>(`${API_ROUTE}/${datasetId}`)
}

export function postDataset(payload: FormData) {
  return post(API_ROUTE, payload, true)
}

export function deleteDataset(datasetId: number) {
  return del(`${API_ROUTE}/${datasetId}`, {})
}

export function putDataset(datasetId: number, payload: FormData | DatasetPutRequest) {
  return put(`${API_ROUTE}/${datasetId}`, payload)
}